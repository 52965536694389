<template>
  <!--begin::Stats Widget 11-->
  <div class="card card-custom gutter-b">
    <!--begin::Body-->
    <div class="card-body p-5">
      <v-row class="d-none d-sm-flex mx-1 px-5">
        <v-col cols="12" sm="12" md="4">
          <span style="min-width: 50px">
            <span class=" font-poppins font-weight-bold">Order #</span>
          </span>
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <span style="min-width: 120px" class="font-poppins font-weight-bold">Order Details</span>
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <th style="min-width: 120px" class="font-poppins font-weight-bold">Order Status</th>
        </v-col>
      </v-row>
      <template v-if="orders.length>0">
        <v-row
          class="mt-5 mx-1 p-3"
          style="background-color:#F6FBFF;border-radius:20px;"
          v-for="(item, i) in orders"
          v-bind:key="i"
        >
          <v-col cols="12" sm="12" md="4">
            <span
              class=" font-weight-normal d-block font-size-lg"
            >{{ item.customer.name }} # {{ item.order_id }}</span>
            <span class="text-muted font-weight-bold d-block">
              <b-badge v-if="item.payment_method == 'card'" class="mr-1 font-poppins" variant="success">PAID/CARD</b-badge>
              <b-badge v-else-if="item.status === colctd" class="mr-1 font-poppins" variant="primary">PAID/CASH</b-badge>
              <b-badge v-else class="mr-1" variant="danger font-poppins">UNPAID</b-badge>
            </span>
          </v-col>
          <v-col cols="12" sm="12" md="4">
            <span
              v-if="item.type == colctn"
              class=" font-weight-normal d-block font-size-lg font-poppins"
            >
              Collection: {{ item.collection_date }}
              {{ item.collection_time }}
            </span>
            <span class=" font-weight-normal font-poppins">
              Ordered on: {{ item.created_at }}
              <br />
              Order Value: {{$store.state.auth.currency}}{{ (parseFloat(item.total) - parseFloat(item.deal_discount)).toFixed(2) }}
            </span>
          </v-col>
          <v-col cols="12" sm="12" md="4">
            <b-badge v-if="item.status === cncld" class="mr-1 font-poppins" variant="danger">
              <span class="font-weight-normal ">{{ item.status }}</span>
              &nbsp;
              <span>{{ item.status_date }}</span>
            </b-badge>
            <b-badge v-if="item.status === pend" class="mr-1 font-poppins" variant="primary">
              <span class="font-weight-normal">{{ item.status }}</span>
              &nbsp;
              <span>{{ item.status_date }}</span>
            </b-badge>
            <b-badge v-if="item.status === ack" class="mr-1 font-poppins" variant="warning">
              <span class="font-weight-normal">{{ item.status }}</span>
              &nbsp;
              <span>{{ item.status_date }}</span>
            </b-badge>
            <b-badge
              v-if="item.status === colctd || item.status === delvrd"
              class="mr-1 font-poppins"
              variant="success"
            >
              <span class="font-weight-normal ">{{ item.status }}</span>

              &nbsp;
              <span>{{ item.status_date }}</span>
            </b-badge>
          </v-col>
        </v-row>
      </template>
      <template v-if="isloading">
        <div class="loader-container">
          <v-progress-circular
            indeterminate
            color="primary"
            size="64"
          ></v-progress-circular>
        </div>
     </template>
      <!-- <div class="table-responsive">
          <table
            class="
              table
              table-head-custom
              table-vertical-center
              table-head-bg
              table-borderless
            "
          >
            <thead>
              <tr class="text-left">
                <th style="min-width: 50px">
                  <span class="text-dark-75">Order #</span>
                </th>
                <th style="min-width: 120px">Order Details</th>
                <th style="min-width: 120px">Order Status</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(item, i) in orders">
                <tr v-bind:key="i">
                  <td>
                    <span
                      class="
                        text-dark-75
                        font-weight-bolder
                        d-block
                        font-size-lg
                      "
                    >
                      {{ item.customer.name }} # {{ item.order_id }}
                    </span>
                    <span class="text-muted font-weight-bold d-block">
                      <b-badge
                        v-if="item.payment_method == 'card'"
                        class="mr-1"
                        variant="success"
                      >
                        PAID/CARD
                      </b-badge>
                      <b-badge
                        v-else-if="item.status === colctd"
                        class="mr-1"
                        variant="primary"
                      >
                        PAID/CASH
                      </b-badge>
                      <b-badge v-else class="mr-1" variant="danger">
                        UNPAID
                      </b-badge>
                    </span>
                  </td>
                  <td>
                    <span
                      v-if="item.type == colctn"
                      class="
                        text-dark-75
                        font-weight-bolder
                        d-block
                        font-size-lg
                      "
                    >
                      Collection: {{ item.collection_date }}
                      {{ item.collection_time }}
                    </span>
                    <span class="text-muted font-weight-bold">
                      Ordered on: {{ item.created_at }} <br />
                      Order Value: {{$store.state.auth.currency}}{{ parseFloat(item.total).toFixed(2) }}
                    </span>
                  </td>
                  <td>
                    <b-badge
                      v-if="item.status === cncld"
                      class="mr-1"
                      variant="danger"
                    >
                    <span class="font-weight-normal">{{ item.status }}</span>
                      &nbsp;
                      <span>{{ item.status_date }}</span>
                    </b-badge>
                    <b-badge
                      v-if="item.status === pend"
                      class="mr-1"
                      variant="primary"
                    >
                    <span class="font-weight-normal">{{ item.status }}</span>
                      &nbsp;
                      <span>{{ item.status_date }}</span>
                    </b-badge>
                    <b-badge
                      v-if="item.status === ack"
                      class="mr-1"
                      variant="warning"
                    >
                    <span class="font-weight-normal">{{ item.status }}</span>
                      &nbsp;
                      <span>{{ item.status_date }}</span>
                    </b-badge>
                    <b-badge
                      v-if="item.status === colctd || item.status === delvrd"
                      class="mr-1"
                      variant="success"
                    >
                    <span class="font-weight-normal">{{ item.status }}</span>
                      &nbsp;
                      <span>{{ item.status_date }}</span>
                    </b-badge>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
      </div>-->
    </div>
    <!--end::Body-->
  </div>
  <!--end::Stats Widget 11-->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TotalOrdersWidget",
  props: ["orders"],
  data() {
    return {
      isloading:false

    };
  },
  computed: {
    ...mapGetters([
      "pending",
      "cancelled",
      "acknowledged",
      "delivered",
      "collected",
      "delivery",
      "collection",
      "orderPrepared",
      "orderWay"
    ]),
    colctd() {
      return this.collected;
    },
    pend() {
      return this.pending;
    },
    ack() {
      return this.acknowledged;
    },
    delvrd() {
      return this.delivered;
    },
    cncld() {
      return this.cancelled;
    },
    dlvry() {
      return this.delivery;
    },
    colctn() {
      return this.collection;
    },
    preprd() {
      return this.orderPrepared;
    },
    onWay() {
      return this.orderWay;
    },
    totalOrders() {
      let count = 0;
      if (this.orders) {
        return this.orders.length;
      }
      return count;
    }
  },
  mounted() {}
};
</script>
<style>
.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh; /* Full viewport height */
  text-align: center;
}
</style>
